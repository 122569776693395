import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Instruction } from '../../../../model/instruction.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'hpm-instruction-table-edit',
  templateUrl: './instruction-table-edit.component.html',
  styleUrl: './instruction-table-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructionTableEditComponent implements OnInit {
  @Input() instructions!: Instruction[];
  @Output() instructionsChange: EventEmitter<Instruction[]> = new EventEmitter<
    Instruction[]
  >();

  ngOnInit(): void {
    this.ensureInstructionsNotEmpty();
  }

  drop(event: CdkDragDrop<string[]>): void {
    const movedElement = this.instructions.splice(event.previousIndex, 1)[0];
    this.instructions.splice(event.currentIndex, 0, movedElement);
  }

  addInstruction(index: number): void {
    const emptyInstruction: Instruction = {
      icon: '',
      what: '',
      when: '',
      how: '',
      where: '',
      dosage: '',
      who: '',
      colorCode: this.instructions[index]?.colorCode,
    };
    this.instructions.splice(index + 1, 0, emptyInstruction);
    this.instructionsChange.emit(this.instructions);
  }

  deleteInstruction(index: number): void {
    this.instructions.splice(index, 1);
    this.ensureInstructionsNotEmpty();
  }

  private ensureInstructionsNotEmpty(): void {
    if (!this.instructions || this.instructions.length === 0) {
      this.instructions = [];
      this.addInstruction(0);
    }
    this.instructionsChange.emit(this.instructions);
  }
}
