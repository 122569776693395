<div class="instructions">
  @for (instruction of instructions; track instruction) {
    <div class="instruction">
      <div class="field what">
        <div class="col-5">
          <hpm-icon-select [icon]="instruction.icon"></hpm-icon-select>
        </div>
        <div class="col-7" [style.background-color]="instruction.colorCode ? '#' + instruction.colorCode : ''">
        <pre [innerHTML]="instruction.what"></pre></div>
      </div>
      <div class="field"><pre [innerHTML]="instruction.when"></pre></div>
      <div class="field"><pre [innerHTML]="instruction.how"></pre></div>
      <div class="field" [style.background-color]="instruction.colorCode ? '#' + instruction.colorCode : ''">
      <pre [innerHTML]="instruction.where"></pre></div>
      <div class="field"><pre [innerHTML]="instruction.dosage"></pre></div>
      <div class="field"><pre [innerHTML]="instruction.who"></pre></div>
    </div>
  }
</div>
