<cx-header>
  <cx-header-title>
    <div class="title">
      {{ 'TEMPLATE_OVERVIEW.TITLE' | translate }}
    </div>
  </cx-header-title>
</cx-header>
<div class="container">
  <hpm-tab-navigation></hpm-tab-navigation>
  <hpm-template-table></hpm-template-table>
</div>
