import { Instruction } from './instruction.model';

export interface Section {
  heading: string;
  headingLevel: number;
  introductionText: string;
  conditionExpression: string;
  pageBreak: boolean;
  instructions: Instruction[];
}

export function getEmptySection(): Section {
  return {
    heading: '',
    headingLevel: 0,
    introductionText: '',
    conditionExpression: '',
    pageBreak: false,
    instructions: [],
  };
}
