<div class="inline-text">
  <div class="editor">
    <ngx-editor-floating-menu [editor]="editor">
      <ngx-editor-menu [editor]="editor"
                       [toolbar]="toolbar"
                       [customMenuRef]="customMenu">
      </ngx-editor-menu>
    </ngx-editor-floating-menu>
    <ngx-editor
      placeholder=""
      [editor]="editor"
      [outputFormat]="'html'"
      [(ngModel)]="text"
      (ngModelChange)="textChange.emit(text)">
    </ngx-editor>
  </div>
</div>

<ng-template #customMenu>
  <div class="color-picker font-select">
    <mat-form-field>
      <mat-label class="font-s">
        {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.FONT_SIZE' | translate }}</mat-label>
      <mat-select (selectionChange)="setFontSize($event.value)" [value]="getFontSize()">
        <mat-option class="font-s" *ngFor="let fontsize of fontSizes" [value]="fontsize">{{fontsize}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="color-picker">
    <button mat-icon-button class="custom-menu-item NgxEditor__MenuItem--Icon" (click)="increaseFontSize()">
      <mat-icon class="material-symbols-outlined">text_increase</mat-icon>
    </button>
  </div>
  <div class="color-picker">
    <button mat-icon-button class="custom-menu-item NgxEditor__MenuItem--Icon" (click)="decreaseFontSize()">
      <mat-icon class="material-symbols-outlined">text_decrease</mat-icon>
    </button>
  </div>
  <div class="NgxEditor__Seperator"></div>
  <div class="color-picker">
    {{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.COLOR_FILL' | translate }}
    <button mat-icon-button class="custom-menu-item NgxEditor__MenuItem--Icon"
            [ngModel]="'#' +backgroundColor"
            [palette]="colorPickerPalette"
            (change)="selectedColor($event)"
            [acceptLabel]="'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.COLOR_ACCEPT' | translate"
            [cancelLabel]="'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION_EDIT.COLOR_CANCEL' | translate"
            ngx-colors-trigger
    >
      <mat-icon class="material-symbols-outlined">format_color_fill</mat-icon>
    </button>
  </div>
</ng-template>
