<div class="row">
  <div class="col-12">
    <hpm-search-bar (searchValue)="addSearchFilter($event)"
                    placeholder="TEMPLATE_OVERVIEW.SEARCH_PLACEHOLDER"
                    [autocompleteOptions]="autocompleteOptions"></hpm-search-bar>
  </div>
  <div class="table col-12">

    <cx-table-container [tableLoading]="tableLoading">
      <cx-table-with-pagination
        [initialTableColumnsOrder]="tableColumnsOrder"
        [data]="shownData"
        [sorting]="tableSettings.sort"
        (sortChange)="onSortingDirectionChanged($event)"
        [minLengthForPaginator]="10"
        [paginatorPageSize]="tableSettings.pageSize"
        [paginatorPageIndex]="tableSettings.pageNumber"
        [paginatorTotalRecordsCount]="totalElementCount"
        (paginatorChange)="onPaginatorChange($event)"
        matSort>

        <ng-container matColumnDef="{{templateColumnDef.NAME}}">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'TEMPLATE_OVERVIEW.TABLE.HEADER.NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="{{templateColumnDef.ACTIONS}}">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <button [disabled]="row.type === templateType.CERTIFICATE"
                    class="cx-icon-stroked-button"
                    mat-stroked-button
                    (click)="openEditor(i)">
              <mat-icon class="material-symbols-outlined">edit</mat-icon>
            </button>

            <button class="cx-icon-stroked-button" mat-stroked-button [disabled]="!isPrivilegedUser()"
                    (click)="copyTemplate(row.id)">
              <mat-icon class="material-symbols-outlined">file_copy</mat-icon>
            </button>
            <button class="cx-icon-stroked-button" mat-stroked-button>
              <mat-icon class="material-symbols-outlined">visibility</mat-icon>
            </button>
            <button class="cx-icon-stroked-button" mat-stroked-button [disabled]="!isPrivilegedUser()"
                    hpmSecureDownload [url]="getDownloadUrl(row.id)" [fileName]="row.name">
              <mat-icon class="material-symbols-outlined">download</mat-icon>
            </button>
            <hpm-template-upload-dialog [disabled]="!isPrivilegedUser()"
                                        [template]="row"
                                        (templateChanged)="loadTemplateData()"
            ></hpm-template-upload-dialog>


            <button class="cx-icon-stroked-button" mat-stroked-button
                    [disabled]="!isUserAdmin()"
                    [hpmConfirmDelete]="row.name" (confirmation)="deleteTemplate($event, row.id)">
              <mat-icon class="material-symbols-outlined">delete</mat-icon>
            </button>


          </mat-cell>
        </ng-container>

      </cx-table-with-pagination>

      <cx-table-loading-indicator
        loadingIndicatorText="{{'TEMPLATE_OVERVIEW.TABLE.LOADING' | translate}}"></cx-table-loading-indicator>
    </cx-table-container>
  </div>
</div>
<cx-floating-button [text]="'TEMPLATE_OVERVIEW.ADD_NEW_TEMPLATE' | translate"
                    routerLink="/template/new"></cx-floating-button>
