import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Instruction } from '../../../model/instruction.model';

@Component({
  selector: 'hpm-instruction-table',
  templateUrl: './instruction-table.component.html',
  styleUrl: './instruction-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // modify innerHtml
  encapsulation: ViewEncapsulation.None,
})
export class InstructionTableComponent {
  @Input() instructions!: Instruction[];
}
