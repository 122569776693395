<cx-header>
  <cx-header-title>
    <div class="row gap">
      <mat-icon class="white-medium-icon" routerLink="/template">list_alt</mat-icon>
      <div>{{ template?.name }}</div>
    </div>
  </cx-header-title>
</cx-header>

<cx-floating-button [text]="'TEMPLATE_EDITOR.SAVE' | translate" icon="save" (click)="saveTemplate()"/>
<cx-second-floating-button [text]="'TEMPLATE_EDITOR.DISCARD' | translate" icon="restart_alt" (click)="discardChanges()" />
<div class="container">
  @if (template) {
    <hpm-template-editor #templateEditor [template]="template"></hpm-template-editor>
  }
</div>
