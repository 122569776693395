import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Section } from '../../../model/section.model';

@Component({
  selector: 'hpm-section-edit',
  templateUrl: './section-edit.component.html',
  styleUrl: './section-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionEditComponent implements OnInit {
  @Input() section!: Section;

  headingToggle = false;
  introductionTextToggle = false;
  conditionExpressionToggle = false;
  instructionsToggle = false;

  ngOnInit(): void {
    this.headingToggle = !!this.section.heading;
    this.introductionTextToggle = !!this.section.introductionText;
    this.conditionExpressionToggle = !!this.section.conditionExpression;
    this.instructionsToggle = !!this.section.instructions?.length;
  }

  changedHeadingToggle(checked: boolean): void {
    this.headingToggle = checked;
    if (!this.headingToggle) {
      this.section.heading = '';
      // @ts-expect-error remove header level
      this.section.headingLevel = null;
    }
  }

  changedConditionExpressionToggle(checked: boolean): void {
    this.conditionExpressionToggle = checked;
    if (!this.conditionExpressionToggle) {
      this.section.conditionExpression = '';
    }
  }

  changedIntroductionTextToggle(checked: boolean): void {
    this.introductionTextToggle = checked;
    if (!this.introductionTextToggle) {
      this.section.introductionText = '';
    }
  }

  changedInstructionsToggle(checked: boolean): void {
    this.instructionsToggle = checked;
    if (!this.instructionsToggle) {
      this.section.instructions = [];
    }
  }
}
