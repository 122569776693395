import { getEmptySection, Section } from './section.model';

export interface Template {
  id: string;
  name: string;
  type: TemplateType;
  version: number;
  schemaVersion: number;
  documentVersion: string;
  wordTemplate: string;
  sections?: Section[];
}

export function getEmptyTemplate(): Template {
  return {
    id: '',
    name: '',
    type: TemplateType.HYGIENE_PLAN,
    version: 0,
    schemaVersion: 0,
    documentVersion: '',
    wordTemplate: '',
    sections: [getEmptySection()],
  };
}

export enum TemplateType {
  CERTIFICATE = 'CERTIFICATE',
  HYGIENE_PLAN = 'HYGIENE_PLAN',
}
