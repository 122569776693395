import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TemplateHttpService } from '../template-overview/template-http.service';
import { catchError, Subject, takeUntil } from 'rxjs';
import { getEmptyTemplate, Template } from '../model/template.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CxHeartBeatService } from '@bbraun/cortex/carousel';
import { MatDialogRef } from '@angular/material/dialog';
import { CxDialogHeartBeatComponent } from '@bbraun/cortex/heart-beat/dialog/heart-beat-dialog.component';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { TemplateEditorComponent } from './template-editor/template-editor.component';

@Component({
  selector: 'hpm-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrl: './template-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateDetailComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  template: Template | undefined;
  private loadingSpinnerRef:
    | MatDialogRef<CxDialogHeartBeatComponent>
    | undefined;

  @ViewChild('templateEditor') templateEditor:
    | TemplateEditorComponent
    | undefined;

  constructor(
    private templateHttpService: TemplateHttpService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private hbService: CxHeartBeatService,
    private snackbarService: CxSnackbarService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const currentId = params.get('id');
      if (currentId && currentId === 'new') {
        this.template = getEmptyTemplate();
        // @ts-expect-error unset id to force the backend to generate one
        this.template.id = null;
      } else if (currentId) {
        this.loadTemplateById(currentId);
      } else {
        console.error('Could not load Template Id from URL');
      }
    });
  }

  private loadTemplateById(currentId: string): void {
    this.setLoading(true);
    this.templateHttpService
      .getById(currentId)
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.setLoading(false);
          throw err;
        }),
      )
      .subscribe((template) => {
        this.template = template;
        this.setLoading(false);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  saveTemplate(): void {
    if (this.template) {
      this.setLoading(true);
      this.templateHttpService
        .save(this.template)
        .pipe(
          takeUntil(this.onDestroy$),
          catchError((err) => {
            if (this.template) {
              this.loadTemplateById(this.template.id);
            }
            this.setLoading(false);
            this.translateService
              .get('TEMPLATE_EDITOR.SAVE_ERROR_MESSAGE')
              .subscribe((message) => {
                this.snackbarService.error(message);
              });
            throw err;
          }),
        )
        .subscribe((templateId) => {
          if (this.template) {
            if (this.template.id === templateId) {
              this.loadTemplateById(this.template.id);
            } else {
              this.router.navigateByUrl(`/template/${templateId}`);
            }
          }
          this.setLoading(false);
          this.translateService
            .get('TEMPLATE_EDITOR.SAVE_SUCESS_MESSAGE')
            .subscribe((message) => {
              this.snackbarService.success(message);
            });
        });
    }
  }

  discardChanges(): void {
    if (this.template) {
      this.loadTemplateById(this.template?.id);
    }
    if (this.templateEditor) {
      this.templateEditor.reset();
    }
  }

  private setLoading(loading: boolean): void {
    if (loading && !this.loadingSpinnerRef) {
      this.loadingSpinnerRef = this.hbService.openHeartBeatDialog('');
    } else if (!loading && this.loadingSpinnerRef) {
      this.loadingSpinnerRef.close();
    }
  }
}
