export const templateColors: string[] = [
  '',
  '#AADEFF',
  '#D5DF7E',
  '#DBDBDB',
  '#DFDBD3',
  '#EE767D',
  '#F9B65A',
  '#F0EDE6',
  '#FFE37D',
];

export const fontSizes = [8, 10, 12, 14, 16, 18, 20, 22, 24];
