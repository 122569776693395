import { MarkSpec } from 'prosemirror-model';
import { Schema } from 'prosemirror-model';
import { nodes, marks as basicMarks } from 'ngx-editor';

const fontSizeMark: MarkSpec = {
  attrs: {
    fontSize: {},
  },
  parseDOM: [
    {
      style: 'font-size',
      getAttrs: (value) => ({ fontSize: value.slice(0, -2) }),
    },
  ],
  toDOM(mark) {
    return ['span', { style: `font-size: ${mark.attrs['fontSize']}px;` }, 0];
  },
};

const marks = Object.assign({}, basicMarks, { fontSize: fontSizeMark });

export const customSchema = new Schema({
  nodes,
  marks,
});
